@import '../../assets/styles/colors.scss';

.VIMList {
  scrollbar-width: thin;
}

.ToolbarFixIframe {
  display: block;
  position: absolute;
  left: 115px;
  // left: 0;
  top: 0;
  width: 500px;
  height: 0;
  z-index: -1;
  border: none;
  margin: 0 !important;
  float: left;
  background: transparent;
}

.RightToolbar {
  flex: 0 0 auto;
  position: relative;
  overflow: visible;
  width: 100%;
}

.DocumentsSelect {
  width: 375px !important;
}

.Space {
  height: 20px;
}

.SplitterBox {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.SplitterPane {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
}

.SplitterPaneContent {
  flex: 1;
}

.SplitterLeftPaneContent {
  overflow: auto;
}

.ListToolbar {
  overflow: visible;
  padding-left: 6px;
  padding-right: 1px;
}

.ListClearFilterBtn {
  position: relative;
  left: -35.14px;
}

.ListArrow {
  width: 16px;

  &[disabled] {
    opacity: 0.2;
  }
}

.ListActiveGroup {
  position: absolute;
  right: 0;
  z-index: 5;
  padding: 8px 5px;
  box-sizing: border-box;
  font-weight: 700;
  background: #f6f6f6;
  border-bottom: 1px solid;
  top: 40px;
  margin-top: 1px;
  left: -1px;
}

.ListPlaceholder {
  opacity: 0.5;
}

.ListItemSuspended {
  color: $warning;
}

.ListMyGroupName {
  text-decoration: underline;
}

.ListItem {
  border-bottom: 1px solid #dee2e6;
  padding: 5px 8px;
  box-sizing: border-box;

  &:hover {
    background-color: #f4f4f4;
  }

  &[data-selected='true'] {
    background: $fxPrimary;
    color: #fff;
  }

  &.CanApprove {
    border-right: 2px solid green;
  }
}

.Row {
  display: flex;
  align-items: center;

  &:nth-child(2) {
    margin-top: 5px;
  }
}

.Date {
  width: 70px;
  flex: 0 0 auto;
}

.RightCell {
  margin-left: auto;
}

.DetailsNoData {
  padding: 5px;
}

.DetailsStatus {
  text-transform: uppercase;
  margin-right: 10px;
}

.DetailsAmountWarning {
  color: $warning;
}

.DetailContent {
  border-top: 1px solid #f4f4f4;
  padding-top: 2px;
  margin-top: 5px;
  position: relative;
}

.ApproveBtn {
  color: $success !important;
}

.ReturnBtn {
  color: $error !important;
}

.BlueBtn {
  color: $fxPrimary !important;
}

.LinkBtn {
  text-decoration: none;
}

.DescriptionTitle {
  text-transform: uppercase;
  font-weight: 700;
}

.DetailsCard {
  padding: 0 5px;
}

.DetailsCardTitle {
  font-weight: 700;
  text-transform: uppercase;
}

.BaseDetailsBox {
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}

.BaseDetailsCol {
  flex: 0 0 auto;
  width: 50%;
  box-sizing: border-box;

  &:nth-child(odd) {
    padding-right: 10px;
  }
}

.BaseDetailsCell {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  display: block;
}

.RedBorderRow td {
  position: relative;
  border-bottom: 1px solid $error;
}

.CellRed {
  color: $error;
}

.FixedGridContainer {
  table {
    table-layout: fixed;
  }
}

.NowrapGrid {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DocumentImg {
  display: block;
  max-width: 100%;
}

.NotesTooltipCell {
  text-align: center;
  position: relative;
}

.NotesIconBox {
  padding: 8px;
  margin: -8px -12px;
  text-align: center;

  :global(.k-icon) {
    pointer-events: none;
  }
}

.GLGrid {
  colgroup col:nth-child(1) {
    width: 32%;
  }
}

/* .ExpandIcon { ??
  margin-top: -2px;
} */
.RejectMultiselect {
  width: 100%;
  margin-bottom: 8px;
}
