@import './assets/styles/colors.scss';

$font-size: 12px;
$kendo-font-size: 12px;
$kendo-drawer-font-size: $font-size;
$kendo-card-font-size: $font-size;
$kendo-border-radius: 2px;
$kendo-time-selector-font-size: 14px;

@import '@progress/kendo-theme-core/scss/functions/index.import.scss';

$kendo-colors: ();
$kendo-colors: k-map-merge(
                $kendo-colors,
                k-generate-color-variations('primary', $fxPrimary, 'bootstrap')
);
:root {
  --color-primary: #2196f3;; //$fxPrimary;
  --color-secondary: $secondary;
  --color-error: $error;
  --color-warning: $warning;
  --color-attention: $attention;
  --color-success: green;
  --color-text: $text;
  --color-fx: $fxColor;
}

html {
  font-size: $font-size;
  font-family: 'Segoe UI', 'Arial', 'Helvetica Neue', Helvetica, sans-serif;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

.mdi {
  font-size: 16px;
}

a,
a:visited,
.link {
  color: $linkColor;
  text-decoration: none;
}

.link {
  cursor: pointer;
}

.fw600 {
  font-weight: 600 !important;
}

.fx-warning-color {
  color: $warning
}

.fx-primary-color {
  color: $fxPrimary
}

.fx-secondary-color {
  color: $secondary
}

.fx-error-color {
  color: $error
}

.fx-attention-color {
  color: $attention
}

.fx-success-color {
  color: $success
}

.pointerEventsNone {
  pointer-events: none;
}

.k-button-icontext .mdi {
  margin: 0 4px 0 -4px;
  line-height: 1.1;
}

.k-toolbar .k-icon-button {
  line-height: 1 !important;
  padding: 2px 4px !important;
}

.k-grid-toolbar {
  border-color: rgba(0, 0, 0, .08) !important;
}

.k-button {
  height: 27.4px;
}

.k-button.noFocus {
  &:focus {
    box-shadow: none;
  }

  &:focus:after {
    opacity: 0;
  }
}

.k-icon-button {
  width: 27.4px;
}

.k-grid .k-grid-md .k-table-th, .k-grid-md .k-table-th {
  padding-block: 4px !important;
  padding-inline: 8px !important;
  padding: 4px 8px !important;
}

.k-grid .k-grid-header .k-table-th {
  vertical-align: middle !important;
}

button.k-button-link {
  background: none;
  border: none;
  border-radius: 0 !important;
}

.k-button img.k-button-icon {
  max-width: 100%;
  max-height: 100%;
}

.k-edit-cell > .k-textbox,
.k-edit-cell > .k-widget:not(.k-switch),
.k-grid-edit-row > td > .k-textbox,
.k-grid-edit-row > td > .k-widget:not(.k-switch) {
  margin-left: 0 !important;
  width: 100% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  resize: none !important;
}

input[type=number] {
  -moz-appearance: textfield;
}

.k-animation-container {
  z-index: 100003 !important;
}

.k-actions,
.k-action-buttons,
.k-columnmenu-actions {
  justify-content: flex-end;
}

@import '@progress/kendo-theme-default/dist/all.scss';
/*.k-overlay {
  z-index: 10001;
}*/

/*.k-dialog-wrapper {
  z-index: 10005 !important;
}*/

.k-dropdowntree.k-picker {
  background: none;
}

.k-dropdowntree.k-picker .k-input-button {
  background-color: #f5f5f5;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.k-grid th,
.k-grid td,
.k-grid .k-table-th,
.k-grid .k-grid-edit-row > td {
  padding: 4px 8px;
  word-break: break-all;
  text-overflow: initial;
  padding-block: 4px;
  padding-inline: 8px;
}

.k-grid th {
  height: 24px;
}

.k-grid td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-block: 0 !important;
  padding-inline: 4px !important;
  border-left: 1px solid rgba(0, 0, 0, .08) !important;
}

.k-grid-header .k-header {
  vertical-align: middle;
  line-height: 1.2;
}

.k-grid-column-menu.k-grid-filter {
  height: auto;
  width: auto;
  padding: 2px;
}

.k-grid .k-grid-header-menu.k-grid-column-menu .k-i-filter::before {
  content: "\e031";
}

.k-grid-norecords {
  width: 100% !important;
}

.k-grid-norecords-template {
  border: none;
}

.k-header > .k-cell-inner {
  margin: -8px calc(-1.42857em - -2px) -8px -12px;
}

.k-menu:not(.k-context-menu) > .k-item {
  color: var(--color-text);
}

.k-menu:not(.k-context-menu) > .k-item:hover, .k-menu:not(.k-context-menu) > .k-item.k-hover {
  background: $fxPrimary;
  color: #fff;
}

.k-column-title {
  white-space: initial;
}

.k-master-row > td > span > .k-checkbox {
  top: -1px;
  position: relative;
  left: 5px;
}

[dir="ltr"] label.k-checkbox-label {
  margin-left: 4px !important;
}

[dir="rtl"] label.k-checkbox-label {
  margin-right: 4px !important;
}

.k-window {
  max-width: 100%;
}


.k-window-title {
  align-items: center;
  font-size: 1.3rem !important;
  line-height: 1 !important;
}

.k-window-titlebar,
.k-dialog-titlebar,
.k-window-content {
  padding-block: 8px;
  padding-inline: 8px;
  padding: 8px !important;
}

.k-window-titlebar {
  background-color: $fxPrimary;
  color: #fff;
}


.k-window-actions .k-button {
  border: none;
}

.k-button:disabled, .k-button.k-disabled {
  opacity: 0.4;
}

.k-tooltip {
  border-color: rgb(64, 64, 64);
  background-color: rgb(64, 64, 64);
  padding: 4px;

  a,
  a:visited,
  .link {
    color: $linkOnDarkColor;
  }
}

.k-chip {
  font-size: 12px;
  border-radius: 12px !important;
  min-height: 12px;
}

.k-popover-body {
  padding: 4px;
  position: relative;
  z-index: 1;
}

.k-popover-callout {
  width: 13px;
  height: 13px;
  z-index: 0;
}

.k-multiselect .k-icon.k-i-loading {
  align-self: center;
}

.k-combobox {
  align-items: center;
}

.k-combobox .k-icon.k-i-loading,
.k-multiselect .k-icon.k-i-loading {
  margin-right: 5px;
}

.k-stepper .k-step-label .k-step-text {
  white-space: pre-wrap;
}

/*kendo end*/
/* SPLITTER */
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.Resizer.horizontal {
  height: 15px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
}

.Resizer.vertical {
  width: 15px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.DragLayer {
  z-index: 1;
  pointer-events: none;
}

.DragLayer.resizing {
  pointer-events: auto;
}

.DragLayer.horizontal {
  cursor: row-resize;
}

.DragLayer.vertical {
  cursor: col-resize;
}

/* SPLITTER END */

/* IMG Viewer */
.react-viewer-toolbar {
  display: flex !important;
  justify-content: center;
}

.react-viewer-toolbar li {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.react-viewer-icon {
  font-size: 12px !important;
}

/* IMG Viewer END */
.k-i-file-excel {
  color: #13761a;
}

.k-i-file-pdf {
  color: #c11a1a;
}

.leaflet-site-search-control {
  height: 28px;
  z-index: 1;
}

.leaflet-control-mapquest-geocoding.mq-control.leaflet-control {
  margin-top: 9px;
}

.dropdown-scroll-popup .k-list-content {
  scrollbar-width: auto;
}

.dropdowntree-scroll-popup .k-treeview {
  scrollbar-width: thin;
  max-height: 250px;
}

.TCRequestActionsCard {
  z-index: 999999999999999999 !important;
}

.TCCard {
  z-index: 20000 !important;
}