@import '../../assets/styles/colors.scss';

.Views {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px;
}

.AdditionalVew {
  &:hover {
    background: #f1f1f1;
  }
}

.DefaultViews {
  justify-content: center;
  gap: 8px;
}

.CurrencyCellContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.CenterCellContent {
  text-align: center;
  justify-content: center;
}

.THWithArrow {
  border-bottom: 2px solid;
}

.THWithArrowInner {
  display: flex;
  align-items: center;
  gap: 2px
}

.THArrow {
  font-size: 26px;
  line-height: 1;
}

.THArrowBlack {
  :global(.mdi) {
    color: #333b48;
  }

  border-color: #333b48;
}

.THArrowGreyPink {
  border-color: #948197;

  :global(.mdi) {
    color: #948197;
  }

}

.THArrowBlue {
  border-color: #48a4d7;

  :global(.mdi) {
    color: #48a4d7;
  }
}

.THArrowGreenBlue {
  border-color: #47abb7;

  :global(.mdi) {
    color: #47abb7;
  }
}

.THArrowOrange {
  border-color: #f4a24e;

  :global(.mdi) {
    color: #f4a24e;
  }
}

.THArrowRed {
  border-color: #f16f79;

  :global(.mdi) {
    color: #f16f79;
  }
}

.NotApplicableMilestoneCell {
  color: #888;

  &:empty:before {
    content: 'N/A';
  }
}

.ActionsContainer {
  height: 100%;
  display: flex;;
  align-items: center;
  gap: 8px;
  margin-right: 8px;
}
