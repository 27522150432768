@import '../../assets/styles/colors.scss';

$appBarHeight: 50px;
.PageWrapper {
  position: absolute;
  top: 0;
  left: 46px;
  right: 0;
  bottom: 0;
}

.DrawerRow {
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 8px;
}

.DrawerItemText {
  display: inline-block;
  padding: 0 8px;
  white-space: nowrap;
}

.PageHidden {
  visibility: hidden;
  position: relative;
  z-index: -1;
  display: none;
}


.ToggleDrawer {
  color: $fxPrimary;
}

.InfoLink {
  text-decoration: none;
  color: inherit;
  top: 0;
  line-height: 1.5;
  margin-left: auto;
  align-self: flex-start;
  display: inline-block;
  padding: 4px;
}

.Logo {
  width: 90px;
  height: 18.49px;
  display: block;
  margin: 0 5px;
}

.MenuIcon {
  margin: 0 5px;
  font-size: 23px;
}

.MenuBadge {
  top: 2px;
  left: 2px;
  padding: 3px 1px !important;
  min-width: 15px;
  height: 15px;
  width: auto !important;
  transform: none !important;
  text-align: center;
  font-size: 9px;

  &:empty {
    display: none;
  }
}

.ToggleDrawer {
  width: 45px;
  height: $appBarHeight;
  cursor: pointer;
  color: #000;

  :global(.mdi) {
    font-size: 23px;
    margin-left: 4px;
  }
}

.mainAppBar {
  height: $appBarHeight;
  z-index: 10002;
  position: relative;
  padding: 0;
  padding-right: 8px;
  box-shadow: none;
  border-bottom: 1px solid #ccc;
  background-color: #f2f2f2;
}

.mainMenuDrawer {
  position: absolute;
  top: $appBarHeight;
  left: 0;
  bottom: 0;
  right: 0;

  :global(.k-drawer) {
    top: $appBarHeight;
    bottom: 0;
    height: auto;
  }
}

.CompanyCode {
  padding: 8px;
}

.panelBarItem {
  :global(.k-link) {
    cursor: pointer !important;
    box-shadow: none !important;
  }

  :global(.k-link.k-selected) {
    background-color: #ffffff !important;
  }

  :global(.k-link),
  :global(.k-panelbar-item-icon) {
    color: #424242 !important;
  }
}

.lastLogins {
  max-height: 300px;
  overflow: auto;
  scrollbar-width: thin;
}

.panelBarItemIntegration {
  :global(.k-link) {
    display: none !important;
  }
}

.logoutItem {
  :global(.k-link),
  :global(.k-panelbar-item-icon) {
    color: red !important;
  }
}

.authorizedItem {
  :global(.k-panelbar-item-icon) {
    color: green !important;
  }
}

.unAuthorizesItem {
  :global(.k-panelbar-item-icon) {
    color: red !important;
  }
}

.AvatarContainer {
  transition: 0.2s;
  padding: 8px;
  cursor: pointer;

}

.appBarPopup {
  border: none;
  max-height: 90vh;
  overflow: auto;
  scrollbar-width: thin;
}

.appBarPopupContainer {
  width: 230px;
}